// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeUndo,
    placeRedo,
    placeBoldText,
    placeBoldLeftText,
    placeErase,
    plotFunction,
    createAxes,
    createSpace,
    drawPoint,
    writeMediumText,
    writeSmallText,
    writeLargeText,
    drawSegment,
    placeSq,
    drawArrow,
    writeHTMLText
} from '../Utils.js';
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
JXG.Options.board.minimizeReflow = 'none';
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.image.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS//////////////////////////////////
var graph =createSpace(-1,9,-4,6);
graph.options.layer['axis']=2;
graph.options.layer['text']=12;
graph.options.layer['image']=10;
graph.options.layer['line']=6;
graph.options.layer['point']=12;
graph.options.layer['angle']=14;
graph.options.image.highlight=false;
/////////////////////////////////////////////////////////////////////
makeResponsive(graph);
placeLogo(graph);
///////////////////////////////////////////////////////////
var ax = createAxes(graph);
var i=1;
placeTitle(graph, 'Average Value of a Function');
var next=placeRedo(graph);
var prev=placeUndo(graph, 'left');
////////////////////////////s/////
var sin = plotFunction(graph, function(x){if(i==1){return 2+1*Math.sin(Math.PI*x/2)}else if(i==2){return 3+2*Math.cos(Math.PI*x/2)}else if(i==3){return 2.5+2*Math.sin(Math.PI*x/2)*Math.cos(Math.PI*x/2)}}, 0, 4);
//////////////////
var ang =placeSlider(graph, 0, -2.25, 0, 0.5, 4, 4, 'x')
ang.setAttribute({snapWidth:0.25});
//////////////////////
var int =graph.create('integral', [[0.0, 2.0*Math.PI], sin],{curveLeft:{name:'x=a',size:0, color:'black'},curveRight:{name:'x=b',size:0, color:'black'}, label:{visible:false}});
///////////////////////
var sinPt1=drawPoint(graph, ()=>ang.Value(), function(){if(i==1){return 2+1*Math.sin(Math.PI*ang.Value()/2)} else if(i==2){return 3+2*Math.cos(Math.PI*ang.Value()/2)} else if(i==3){return 2.5+2*Math.sin(Math.PI*ang.Value()/2)*Math.cos(Math.PI*ang.Value()/2)}});
////////////
sinPt1.setAttribute({fixed:true, size:()=>Math.round(4*graph.canvasWidth/800.), visible:true, fillColor:'white', strokeColor:'black', strokeWidth:2});
/////////////////////////////////
var sinPt2=drawPoint(graph, 0, -0.5);
////////////
sinPt2.setAttribute({fixed:true, size:0, visible:true, fillColor:'white', strokeColor:'black', strokeWidth:2});
/////////////////////////
var sinPt3=drawPoint(graph, 4, -0.5);
////////////
sinPt3.setAttribute({fixed:true, size:0, visible:true, fillColor:'white', strokeColor:'black', strokeWidth:2});
///////////////////////
var sinPt4=drawPoint(graph, -0.35, 0);
sinPt4.setAttribute({fixed:true, size:0, visible:true, fillColor:'white', strokeColor:'black', strokeWidth:2});
/////////////////////////////////
var sinPt5=drawPoint(graph, -0.35, ()=>sinPt1.Y());
sinPt5.setAttribute({fixed:true, size:0, visible:true, fillColor:'white', strokeColor:'black', strokeWidth:2});
////////////////
var rec = placeSq(graph, [0, 0], [4, ()=>sinPt1.Y()], 'white');
rec.setAttribute({fillOpacity:0.65, borders:{visible:true, color:'black', strokeWidth:2, dash:1}});
//////////////////////////////////////////
var arr1 = drawArrow(graph, sinPt2, sinPt3);
arr1.setAttribute({strokeColor:'black', firstArrow:true, lastArrow:true});
//////////////////////////
var arr2 = drawArrow(graph, sinPt4, sinPt5);
arr2.setAttribute({strokeColor:'black', firstArrow:true, lastArrow:true});

writeHTMLText(graph, 4.5, -0.5, 'x = b');
writeHTMLText(graph, -0.5, -0.5, 'x = a');
writeHTMLText(graph, 2, -0.75, 'b-a');
writeHTMLText(graph, 8.25, 0.25, 'x');
writeHTMLText(graph, -.65, ()=>0.5*sinPt1.Y(), 'f(c)');
placeLeftText(graph, 0., -1.5, 'Use the slider below to drag point c along the curve');
var textSin=writeHTMLText(graph, 4.25, 3.5, function(){return 'Area (red curve) = &int;_a^b f(x) dx = '+(int.Value()).toFixed(2)});
textSin.setAttribute({fontSize:Math.round(25*graph.canvasWidth/800.), anchorX:'left', anchorY:'middle'});
var textRec=writeHTMLText(graph, 4.25, 2.5, function(){return 'Area (white rectangle) = f(c)(b-a) = '+(sinPt1.Y()*4).toFixed(2)});
textRec.setAttribute({fontSize:Math.round(25*graph.canvasWidth/800.), anchorX:'left', anchorY:'middle'});
//////////////////////
var avgF= placeLeftText(graph, 4.25, 1.5, function(){return 'This is the average value of the function.'});
avgF.setAttribute({visible:function(){return (4.00*sinPt1.Y()).toFixed(2)==(int.Value()).toFixed(2)},color:'green',  fontSize:Math.round(25*graph.canvasWidth/800.), anchorX:'left', anchorY:'middle'});
//////////////////////////////////////
next.on('down', function(){if(i<3){return i=i+1}else{i=1}; console.log("i::", i)});
prev.on('down', function(){if(i>1){return i=i-1}else{i=3}; console.log("i::", i)});
/////////////////////////////////////////////////////
////////////////////////////////////////////END OF OPERATIONS///////////////////////
},
}
export default Boxes;
