<template>
  <div style="margin:20px">
    <h3 ref="intro">
      Average value of a function
    </h3>

    <p class="big mt-3">
      Let's say \(f(x)\) is a continuous function over an interval \([a, b]\).
      Then there exists a point \(x=c\) in this interval such that &mdash;
      $$ f(c)(b-a) = \int_a^b f(x)dx$$
      The value of function \(f\) at \(x=c\) is then called its average value over the interval \([a, b]\).
    </p>
    <h3 ref="pg">
      MagicGraph | Graphical Interpretation of Average Value of a Function
    </h3>
    <br>
    <h5>Getting Started</h5>
    <p class="big mt-3">
      Shown in this MagicGraph is the graph of a function \(f(x)\) that is continous over the interval [a, b].<br>
      You are also shown a point \(c\) that can slide on the graph. You can slide this point on the curve using the slider shown.
      <br>
      To find the average value of the function \(f\), slide the point \(c\) on the graph until the area under the white rectangle becomes equal to the area under the red curve.
      <br>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      You can tap on <i class="next ma-1" /> icon to go to next function.
      You can tap on the <i class="previous ma-1" /> icon to go to previous function.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'AverageValueOfFunctions',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Average Value of a Function';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Average Value of a Function',
          //titleTemplate: '%s | Learn interactively',
          meta: [
                  {vmid:'description', name: 'description', content: 'Learn interactively about average value of a function'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
